import { Injectable, inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpRequest, HttpHandler,HttpEvent, HttpInterceptor, HttpContextToken, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, finalize, tap } from 'rxjs';
import { LoaderService } from 'src/app/shared/components/globals/loader/loader.service';
export const LOADING_IGNORED = new HttpContextToken<boolean>(()=> false);

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {

  private loader:        LoaderService = inject(LoaderService);
  private readonly api:  string        = environment.apiUrl;

  /**
   * ### API URL Interceptor
   * Este interceptor tiene como responsabilidad "interceptar" todas las peticiones api (aquellas que no son de assets o http).
   * Si la petición es una petición a la API, se le añaden las credenciales asi como la url de la api. 
   * Además, este interceptor controla el servicio del Loader, y automatiza cuando hay o no una petición para mostrarlo o esconderlo respectivamente.
   * Para ello, toda petición lanzada a la api y que sea de Type 0 (REQUEST) no response ya que si no descuadra el contador del loader, para la response nos esperamos
   * al hook finalize y escondemos el loader. 
   * @param request 
   * @param next 
   * @returns 
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const finalRequest = request.url.startsWith('http') || request.url.includes('/assets/') ? 
      request : 
      request.clone({ url: this.api + request.url, withCredentials: true });
    
    return next.handle(finalRequest).pipe(
      tap((v: HttpEvent<any>) => v.type === 0 && request.context.get(LOADING_IGNORED) === false ? this.loader.show() : false), 
      finalize(() => this.loader.hide()));
  }

}

export const apiUrlInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: ApiUrlInterceptor,
  multi: true
}