import { environments } from "src/app/shared/models/environment.model";

export const environment: environments = {
    
    apiUrl: 'https://dev-chelseafc.3ddigitalvenue.com/friends-family',

    production: false,

    adyen: {
        environment: 'test',
        clientKey:   'test_P6NWOWMQZFAWBHPHX3JWUUOWBQCBI3GB',
    },

    exchange: {
        chelseafc: {
            buyer_types: {
              ADULT:  { from: 'all', to: 'all' },         
              JUNIOR: { from: '2004-08-01', to: 'today' }, 
              SENIOR: { from: '1900-01-01', to: '1959-07-31' }, 
            },
            hold_codes: {
              1030: {
                code: 'TEADUL',
                name: 'adult',
                label: 'A',
                color: '#001489',
                buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
              },
              1033: {
                code: 'TEADUN',
                name: 'adult non-ambulant',
                color: '#00B1FF',
                buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'ANA',
              },
              1036: {
                code: 'TEADUA',
                name: 'adult ambulant',
                color: '#FB5AAA',
                buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'AAM',
              },
              1031: {
                code: 'TEJNR',
                name: 'junior',
                color: '#C13030',
                buyer_type: 'JUNIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'J',
              },
              1034: {
                code: 'TEJNRN',
                name: 'junior non-ambulant',
                color: '#FFA700',
                buyer_type: 'JUNIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'JNA',
              },
              1037: {
                code: 'TEJNRA',
                name: 'junior ambulant',
                color: '#FCCA24',
                buyer_type: 'JUNIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'JAM',
              },
              1032: {
                code: 'TESEN',
                name: 'senior',
                color: '#59A83C',
                buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'S',
              },
              1035: {
                code: 'TESENN',
                name: 'senior non-ambulant',
                color: '#D5C68A',
                buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'SNA',
              },
              1038: {
                code: 'TESENA',
                name: 'senior ambulant',
                color: '#8B4EB1',
                buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'SAM',
              },
            },
            membership_allowed_buy_tickets: {
              '2425GA': [
                'GAST'
              ],
              '2425WS': [
                "STT_NT",
                "PSTAFF",
                "PRADUL",
                "ADTONL",
                "TEEN",
                "JUNIOR",
                "AMTONL",
                "AMPRAD",
                "AMJUN",
                "AMTEEN",
                "NATONL",
                "NAPRAD",
                "NAJUN",
                "NATEEN",
                "SCPPCL"
              ]
            }
        },
        local: {
            buyer_types: {
              ADULT: { from: 'all', to: 'all' }, // Everyone can buy ADULT
              JUNIOR: { from: '2002-08-01', to: 'today' }, // Supporters born on or after 1st Aug 2002 (01/08/2002 - today)
              SENIOR: { from: '1900-01-01', to: '1957-07-31' }, // Supporters born on or before 31st July 1957 (31/07/1957)
            },
            hold_codes: {
              1108: {
                code: 'MMCAD',
                name: 'adult',
                label: 'A',
                color: '#001489',
                buyer_type: 'ADULT', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
              },
              1109: {
                code: 'MMCJR',
                name: 'junior',
                label: 'J',
                color: '#C13030',
                buyer_type: 'junior', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
              },
              1110: {
                code: 'MMCSR',
                name: 'senior',
                color: '#59A83C',
                buyer_type: 'SENIOR', // se debe usar esta string para hacer match en el objeto `buyer_types` de exchange y conseguir las reglas de fecha nacimiento
                label: 'S',
              },
            },
        },
    },

    familyEnclosure: {
      chelseafc: {
        list_family_enclosure_sections:   	       ['1135', '1001'],
        family_buyer_types: {
          default: {
            family_enclosure_buyer_type_id:        [1741],
            family_enclosure_buyer_type_id_junior: [1012],
            family_enclosure_buyer_type_id_senior: [1013],
          },
          exchange: {
            family_enclosure_buyer_type_id:        [1510, 1511, 1512],
            family_enclosure_buyer_type_id_junior: [1502, 1505, 1508],
            family_enclosure_buyer_type_id_senior: [1503, 1506, 1509],
          }
        }
      },
      local: {
        list_family_enclosure_sections: [],
        family_buyer_types: {
          default: {
            family_enclosure_buyer_type_id:        [],
            family_enclosure_buyer_type_id_junior: [],
            family_enclosure_buyer_type_id_senior: [],
          },
          exchange: {
            family_enclosure_buyer_type_id:        [],
            family_enclosure_buyer_type_id_junior: [],
            family_enclosure_buyer_type_id_senior: [],
          }
        }
      }
    }
}